import { useEffect, useState } from "react"
import Header from "./components/Header"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import { getContentfulDoc } from "./utils/contentful"

import classes from "./styles/Home.module.scss"
import Button from "./components/Button"
import { Helmet } from "react-helmet"
import Logo from "./components/Logo"

const App = () => {
  const [loading, setLoading] = useState(false)
  const [doc, setDoc] = useState<ContentfulDoc>()
  const [password, setPassword] = useState<string>("")

  const slug = window.location.pathname.substring(1)
  useEffect(() => {
    if (!slug) {
      setLoading(false)
      setDoc({ error: "לא נמצא קובץ" })
      return
    }
  }, [])

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()

    setLoading(true)

    getContentfulDoc(slug, password).then((result) => {
      setDoc(result)
      setLoading(false)
    })
  }

  if (window.location.pathname === "/") {
    return (
      <div className={classes.messageContainer}>
        <Logo height={48} />
        <div className={classes.message}>הקובץ המבוקש לא נמצא</div>
      </div>
    )
  }
  if (doc?.error && doc.error !== "PASSWORD") {
    return (
      <div className={classes.messageContainer}>
        <Logo height={48} />
        <div className={classes.message}>{doc.error}</div>
      </div>
    )
  }

  if (!doc || !doc.file) {
    return (
      <div className={classes.messageContainer}>
        <Logo height={48} />
        <form onSubmit={onSubmit}>
          <input
            type="text"
            value={password}
            placeholder="סיסמה לפתיחת הקובץ"
            maxLength={6}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <Button type="button" size="small" disabled={loading}>
            שליחה
          </Button>
          <div className={classes.message}>{doc?.error && "סיסמא שגויה"}</div>
        </form>
      </div>
    )
  }

  const docFileComponents = doc.file.split(".")
  const fileType = docFileComponents[docFileComponents.length - 1]
  const docs = doc && doc.file ? [{ uri: doc.file, fileType }] : []

  return (
    <>
      <Header />
      <Helmet>{doc && doc.title && <title>{doc.title}</title>}</Helmet>
      <main className={classes.root}>
        {doc && (
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false,
              },
            }}
          />
        )}
      </main>
      {doc && (
        <footer className={classes.footer}>
          <Button href={doc.file} download size="small">
            הורדת הקובץ
          </Button>
        </footer>
      )}
    </>
  )
}

export default App
