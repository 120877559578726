import clsx from "clsx"
import classes from "../styles/Button.module.scss"

type ButtonVariant = "contained" | "outlined"
type ButtonType = "button" | "link"
interface ButtonProps {
  children: any
  onClick?(): void
  href?: string
  variant?: ButtonVariant
  type?: ButtonType
  disabled?: boolean
  download?: boolean
  size?: "default" | "small"
  className?: string
}

const Button = (props: ButtonProps) => {
  const {
    children,
    disabled,
    className,
    download,
    size = "default",
    type = "link",
    href = "#",
    variant = "contained",
  } = props

  if (type === "link") {
    if (href) {
      return (
        <a
          href={href}
          download={download}
          className={clsx(classes.root, className, {
            [classes.contained]: variant === "contained",
            [classes.outlined]: variant === "outlined",
            [classes.small]: size === "small",
          })}
        >
          {children}
        </a>
      )
    }
    return (
      <a
        href={href}
        download={download}
        className={clsx(classes.root, {
          [classes.contained]: variant === "contained",
          [classes.outlined]: variant === "outlined",
          [classes.small]: size === "small",
        })}
      >
        {children}
      </a>
    )
  }
  return (
    <input
      type="submit"
      className={clsx(classes.root, {
        [classes.disabled]: disabled,
        [classes.contained]: variant === "contained",
        [classes.outlined]: variant === "outlined",
        [classes.small]: size === "small",
      })}
      value={children}
    />
  )
}

export default Button
