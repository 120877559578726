import Logo from "./Logo"
import classes from "../styles/Header.module.scss"

const Header = () => {
  return (
    <header className={classes.root}>
      <Logo />
    </header>
  )
}

export default Header
