export const getContentfulDoc = async (
  slug: string,
  password: string
): Promise<ContentfulDoc> => {
  const baseUrl = "https://hello-web.co.il/api/getDoc"
  const res = await fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify({ slug, password }),
    headers: {
      "content-type": "application/json",
    },
  })
  const body = await res.json()

  if (body.error) {
    return {
      error: body.error,
    }
  }
  if (!body.success) {
    return {
      error: "PASSWORD",
    }
  }

  return { file: body.doc.file, title: body.doc.title }
}
