interface LogoProps {
  height?: number
}

const Logo = (props: LogoProps) => {
  const { height = 22 } = props
  return (
    <svg
      width={height * 3.6}
      height={height}
      viewBox="0 0 114 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.813965 31.7736V0H6.59292V13.4042C7.30193 12.6977 8.11504 12.1622 9.03506 11.7977C9.95507 11.4332 10.9342 11.251 11.9752 11.251C13.3932 11.251 14.7155 11.5874 15.945 12.2603C17.1746 12.9332 18.1705 13.9144 18.933 15.2069C19.6983 16.4966 20.0781 18.0582 20.0781 19.8918V31.7736H14.2991V20.6516C14.2991 19.5497 13.9671 18.5657 13.3032 17.7049C12.6392 16.8442 11.6854 16.4153 10.4474 16.4153C9.45146 16.4153 8.55957 16.7517 7.7746 17.4246C6.98963 18.0975 6.59574 19.1516 6.59574 20.5843V31.7708H0.813965V31.7736Z"
        fill="#F7F7F7"
      />
      <path d="M47.3672 31.7736V0H53.0589V31.7736H47.3672Z" fill="#F7F7F7" />
      <path d="M57.7357 31.7736V0H63.4274V31.7736H57.7357Z" fill="#F7F7F7" />
      <path
        d="M96.6542 16.2852C99.419 16.2852 101.67 18.5376 101.67 21.3038C101.67 24.0699 99.419 26.3223 96.6542 26.3223H77.6923C74.9274 26.3223 72.676 24.0699 72.676 21.3038C72.676 18.5376 74.9274 16.2852 77.6923 16.2852H96.6542ZM96.6542 10.64H77.6923C71.8071 10.64 67.0334 15.413 67.0334 21.3038C67.0334 27.1917 71.8042 31.9675 77.6923 31.9675H96.6542C102.539 31.9675 107.313 27.1945 107.313 21.3038C107.313 15.4158 102.542 10.64 96.6542 10.64Z"
        fill="#F7F7F7"
      />
      <path
        d="M110.559 31.6034C112.01 31.6034 113.186 30.4342 113.186 28.9919C113.186 27.5496 112.01 26.3804 110.559 26.3804C109.108 26.3804 107.932 27.5496 107.932 28.9919C107.932 30.4342 109.108 31.6034 110.559 31.6034Z"
        fill="#8CCBC0"
      />
      <path
        d="M43.9615 20.9744C43.9615 21.7144 43.912 22.3804 43.8131 22.9724H28.793C28.9167 24.4523 29.4359 25.6116 30.3507 26.4502C31.2655 27.2889 32.3904 27.7082 33.7256 27.7082C35.6541 27.7082 37.0263 26.8819 37.8422 25.2293H43.4422C42.8489 27.2025 41.7115 28.8305 40.0303 30.1131C38.349 31.371 36.2845 32 33.8368 32C31.8589 32 30.0787 31.5684 28.4963 30.7051C26.9387 29.8171 25.7148 28.5715 24.8248 26.9682C23.9594 25.3649 23.5267 23.515 23.5267 21.4184C23.5267 19.2972 23.9594 17.4349 24.8248 15.8317C25.6901 14.2284 26.9016 12.9951 28.4593 12.1318C30.0169 11.2685 31.8094 10.8369 33.8368 10.8369C35.79 10.8369 37.5331 11.2562 39.066 12.0948C40.6237 12.9334 41.8228 14.1297 42.6634 15.6837C43.5288 17.2129 43.9615 18.9765 43.9615 20.9744ZM38.5839 19.4945C38.5592 18.1626 38.077 17.1019 37.1375 16.3126C36.198 15.4987 35.0483 15.0917 33.6885 15.0917C32.4028 15.0917 31.3149 15.4863 30.4248 16.2756C29.5595 17.0403 29.0279 18.1132 28.8301 19.4945H38.5839Z"
        fill="#F7F7F7"
      />
    </svg>
  )
}

export default Logo
